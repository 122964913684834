import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://70f96e1a91bc3307798e9718fc2b084d@o4506308721115136.ingest.sentry.io/4506368134086656",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import "@astrojs/vercel/speed-insights"